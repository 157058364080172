<template>
	<div>
		<draggable
			v-model="paginated_incidents"
			v-bind="dragOptions"
			handle=".handle"
			@start="drag = true"
			@end="drag = false"
			@sort="sort"
		>
			<q-list
				v-for="incident in paginated_incidents"
				:key="incident.id"
				:value="incident"
			>
				<q-item tag="label" class="row wrap list-element q-my-sm">
					<div class="col-12 row items-center">
						<div class="col-sm-6 col-12 row">
							<div class="full-width word-break text-left">
								{{incident.reference_number}}.
								<span v-if="incident.title">{{incident.title}}</span>
								<span v-else>{{incident.name}}</span>
							</div>
						</div>
						<div class="col-sm-6 col-12 row items-center justify-end">
							{{formatDate(incident.created_at) | moment('timezone', logged_user.preferred_timezone, 'DD/MM/YYYY HH:mm')}}
							<q-btn
								class="q-ml-sm"
								flat
								round
								color="dark"
								icon="o_edit"
								@click="editIncident(incident)"
							>
								<q-tooltip anchor="top middle" :delay="500">
									Edit
								</q-tooltip>
							</q-btn>
							<q-btn
								class="handle cursor"
								flat
								round
								color="dark"
								icon="o_drag_handle"
							>
								<q-tooltip anchor="top middle" :delay="500">
									Drag and drop
								</q-tooltip>
							</q-btn>
						</div>
					</div>
				</q-item>
			</q-list>
		</draggable>
		<div v-if="paginated_incidents.length == 0">
			<div
				class="flex items-center q-mt-md q-ml-md"
			>
				<q-icon size="2em" name="warning" class="q-mr-sm"/>
				<span>No data available</span>
			</div>
		</div>
		<IncidentsPagination
			:number_of_pages="number_of_pages"
			:items_per_page_options="[20, 50, 100, 250, 'All']"
			:number_of_items_per_page="items_per_page == 'All' ? 0 : items_per_page"
			:new_page_number="page_number"
			@change-page="changePage"
			@change-page-number="changePageNumber"
		/>
	</div>
</template>

<script>
import IncidentsPagination from "@/components/incidents/incidents/IncidentsPagination.vue";
import IncidentsAPI from "@/services/api/Incidents";
import moment from 'moment-timezone';
import lexorder from "@/services/lexorder.js";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Incidents",
	components: {
		draggable,
		IncidentsPagination,
	},
	data() {
		return {
			drag: false,
			page_number: 1,
			items_per_page: 20,
		};
	},
	computed: {
		...mapGetters(['logged_user', "number_of_pages",]),
		paginated_incidents: {
			get() {
				return this.$store.getters.paginated_incidents;
			},
			set(paginated_incidents) {
				this.$store.commit("setPaginatedIncidents", paginated_incidents);
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
				scrollSensitivity: 200,
				forceFallback: true,
			};
		}
	},
	methods: {
		...mapActions(["listIncidentsPagination"]),
		async sort(event) {
			const curr = this.paginated_incidents[event.newIndex];
			curr.order = lexorder.update(event.newIndex, this.paginated_incidents);
			await IncidentsAPI.patchIncident(curr.id, { order: curr.order });
		},
		editIncident(incident){
			this.$router.push({
				name: 'Edit Incident',
				params: {id: incident.id},
			})
		},
		async changePage(page_number) {
			this.page_number = page_number;
			await this.listIncidentsPaginated()
		},
		async changePageNumber(num_pages) {
			this.page_number = 1;
			this.items_per_page = num_pages
			await this.listIncidentsPaginated()
		},
		async listIncidentsPaginated(){
			let filters = {
				"page_number": this.page_number,
				"items_per_page": this.items_per_page,
			}
			this.$q.loading.show({ delay: 400 })
			await this.listIncidentsPagination(filters);
			this.$q.loading.hide()
		},
		formatDate: function(date_start){
			return moment.tz(date_start, 'UTC')
		}
	},
	async created() {
		await this.listIncidentsPaginated()
	},
};
</script>

<style lang="scss" scoped>
	.word-break{
		word-break: break-word
	}
	.wrap{
		flex-wrap: wrap;
	}
	.cursor{
		cursor: all-scroll;
	}
	.list-element{
		padding: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $grey-1;
		border-color: $grey-8;
		font-size: 1rem;
		&:hover {
			background-color: $grey-2;
		}
	}
</style>
