<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Start {{module_name}}
		</h1>
		<IncidentsList />
		<div
			style="position: sticky; bottom: 20px; width: fit-content; margin: 0 auto; margin-top: 8px;"
		>
			<div>
				<q-btn
					v-if="$q.screen.gt.sm"
					:label="'Start ' + module_name"
					rounded
					icon="add"
					color="primary"
					padding="md lg"
					:to="{ name: 'Create Incident'}"
				/>
				<q-btn
					v-else
					round
					icon="add"
					color="primary"
					size="lg"
					:to="{ name: 'Create Incident'}"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Start {{module_name}}
					</q-tooltip>
				</q-btn>
			</div>
		</div>
	</div>
</template>

<script>
import IncidentsList from "@/components/incidents/incidents/IncidentsList.vue";
import { mapGetters } from "vuex";
import store from '@/store/store.js';

export default {
	name: "Start",
	meta: {
		title: `Alert Cascade - Start ${store.getters.module_name}`
	},
	components: {
		IncidentsList,
	},
	computed: mapGetters([ 'module_name' ]),
};
</script>

